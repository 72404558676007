<template>
  <div class="about py-8 px-8">
    <h1 class="text-2xl mb-2">About</h1>

    <h2 class="text-xl mb-2 underline">このサイトについて</h2>
    <p class="mb-4">
      本サイト「開発の友」では仕事で使えるちょっと便利な機能、楽しめる機能を提供しています。  
    </p>

    <h2 class="text-xl mb-2 underline">作者について</h2>
    <p class="mb-4">
      作者については<a href="https://foresuke.com/" target="_blank" rel="noopener noreferrer"
        class="text-blue-500 underline">プロフィールページ</a>をご確認ください。
    </p>

    <h2 class="text-xl mb-2 underline">Google Analyticsについてのプライバシーポリシー</h2>
    <p class="mb-4">
      当サイトはGoogle Analyticsを使用し、利用状況の解析を行っています。収集される情報は匿名で、個人を特定するものではありません。データはGoogleのプライバシーポリシーに基づき管理されます。
    </p>
    <p class="mb-4">
      Google Analyticsのクッキーの詳細や無効化方法に関しては、Googleの公式サイトをご参照ください。
    </p>
    <p class="mb-4">
      <a href="https://www.google.com/intl/ja/policies/technologies/ads/" target="_blank" rel="noopener noreferrer"
        class="text-blue-500 underline">Googleの広告に関するポリシー</a>
    </p>
    <p class="mb-4">
      <a href="https://policies.google.com/privacy?hl=ja" target="_blank" rel="noopener noreferrer"
        class="text-blue-500 underline">Googleのプライバシーポリシー</a>
    </p>
    <p class="mb-4">
      <a href="https://tools.google.com/dlpage/gaoptout?hl=ja" target="_blank" rel="noopener noreferrer"
        class="text-blue-500 underline">Google Analytics Opt-out Browser Add-on</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'AboutPage'
};
</script>
