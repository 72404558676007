<template>
  <footer class="w-full bg-gray-50 text-center p-2 mt-auto">
    <span class="text-black">&copy; 2023 ToolBox</span>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>
